// axios
import axios from 'axios'
import store from '../store'
import router from '../router'
import Vue from 'vue'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API || process.env.baseURL || '',
  withCredentials: true, // Check cross-site Access-Control
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
// eslint-disable-next-line no-undef,no-unused-vars
axiosIns.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`).then(response => {
  //
})
axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent
    /* const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}` */
    // eslint-disable-next-line no-param-reassign
    config.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    // eslint-disable-next-line no-param-reassign
    config.headers.common['Access-Control-Allow-Origin'] = true

    return config
  },
  error => Promise.reject(error),
)
axiosIns.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error.response.data)
  if (error.response.status === 401) {
    store.dispatch('app/logout')
    if (router.currentRoute.name !== 'auth-login') {
      router.push({ name: 'auth-login' })
    }
    //router.push('/login')
  }
  return Promise.reject(error)
})

/*
// eslint-disable-next-line no-shadow
Plugin.install = function (Vue/!* , options *!/) {
  /!* Vue.isframe = frame ? frame.content : false
  window.isframe = frame ? frame.content : false *!/
  // eslint-disable-next-line no-param-reassign
  Vue.axios = axiosIns
  window.axios = axiosIns
  // eslint-disable-next-line no-undef,no-unused-vars
  axiosIns.get('/sanctum/csrf-cookie').then(response => {
    //
  })
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axiosIns
      },
    },
    $axios: {
      get() {
        return axiosIns
      },
    },
  })
}
*/

Vue.prototype.$http = axiosIns

export default axiosIns
